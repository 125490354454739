<template>
  <v-container v-if="vueEventos">
    <template>
      <v-row>
        <v-col cols="12">
          <PageToolbar
            :title="$tc('eventos.listagemevento')"
            icon="date_range"
            dark
          />
        </v-col>

        <v-col cols="12">
          <ListaEventos />
        </v-col>

        <v-dialog
          @keydown.esc="voltarEventos"
          v-model="dialog"
          @click:outside="voltarEventos"
          width="100%"
          max-width="900px"
          scrollable
          :fullscreen="$vuetify.breakpoint.mdAndDown"
        >
          <router-view></router-view>
        </v-dialog>
      </v-row>
    </template>
    <v-btn
      v-if="vueAddEvento"
      :to="{ name: 'NovoEvento' }"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Eventos",
  components: {
    ListaEventos: () => import("./components/ListaEventos.vue"),
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState("Eventos", {
      status: (state) => state.status,
    }),
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueEventos() {
      return this.getAccess("vueEventos", "menu");
    },
    vueAddEvento() {
      return this.getAccess("vueEvento", "adicionar");
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.dialog = newVal.meta && newVal.meta.dialog;
      },
    },
  },
  methods: {
    voltarEventos(event) {
      this.$router.push({
        name: "Eventos",
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style></style>
